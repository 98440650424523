import React from 'react'
import {
  Timeline,
  Events,
  ImageEvent,
  TextEvent,
} from '@merc/react-timeline';
import { story1, story2, story3, story4, story5, story6, story7, story8, story9 } from "../../images";

export function StoryTimeline() {
  return (
    <Timeline>
      <Events>
        <ImageEvent
          date="1976"
          text="Study Links 的真实历史可以追溯到 1976 年，当时来自香港的年轻学生 Eric Fan 移居英国，开始了他自己在英国的留学生涯。
          "
          src={story1}
        >
        </ImageEvent>
        <ImageEvent
          date="1976"
          text="Eric 开始在一所六年级大学学习，在那里他遇到了 Angela。他们在读 A-Level 课程，然后大学毕业。
          "
          src={story2}
        >
        </ImageEvent>
        <ImageEvent
          date="1983"
          text="1983 年，这对年轻夫妇移居香港，在那里 Eric 教授数学和计算机科学，Angela 在学校教英语，Angela 会说流利的粤语，他们婚后育有两个孩子。
          "
          src={story3}
        >
        </ImageEvent>
        <ImageEvent
          date="1988"
          text="在香港呆了 5 年后，为了让他们的孩子接受英国教育，这对夫妇决定搬回英国。Eric 在布拉德福德的一家大公司找到了一份工作，他们有了第三个孩子 Ailie。
          "
          src={story6}
        >
        </ImageEvent>
        <ImageEvent
          date="1989"
          text="Eric 的侄子 Andy 想来英国学习，所以他们给 Andy 找到了一所很好的寄宿学校，Andy 在假期会和他们一起住。这是他们第一次作为监护人的经历。
          "
          src={story7}
        >
        </ImageEvent>
        <ImageEvent
          date="1990-98"
          text="由于这次作为监护人非常成功，第二年，他的妹妹 Carmen 和家人朋友的两个孩子来到由这对夫妇作为监护人所选的学校学习。Angela 继续在当地一所 FE 学院为外国学生教授英语，并代表学院到世界各地招收学生到英国学习。在 90 年代，越来越多的朋友、家人、熟人和朋友的朋友来到英国，Angela 和 Eric 继续帮助他们寻找合适的学校并充当他们的监护人。确保所有学生都快乐和安全是一项非常有意义但又很辛苦的工作。看到学生们在他们的帮助下不断进步和茁壮成长，以及学生的父母对他们的感激之情，他们感到很欣慰。
          "
          src={story8}
        >
        </ImageEvent>
        <TextEvent
          date="1998"
          text="随着收到越来越多的监护请求，他们于 1998 年决定成立自己的监护公司，在他们家中开始了运营之路。由于他们自己无法容纳所有的学生，他们要求朋友和家庭成员为这些主要在约克郡地区的寄宿学校读书的学生提供家庭寄宿，让他们能够在温馨的充满关怀的家庭中生活。"
        />
        <TextEvent
          date="2008"
          text="公司迅速扩大了规模并在英国各地的家庭和学校中享有声誉，到 2008 年，Study Links 拥有自己的营业场所，雇用了五名全职员工，并拥有更多的寄宿家庭。"
        />
        <TextEvent
          date="2013"
          text="2013 年，我们在英国驻北京大使馆举办了“英国教育”活动，特邀业内人士担任演讲嘉宾。其目的是推广英国教育体系，来自中国各地的精英学校、企业、家长和学生参加了该活动。该活动得到了很好的反响，媒体和电视都进行了积极的报道。"
        />
        <ImageEvent
          date="现在"
          text="公司不断发展壮大，在英国各地的学校拥有来自多个国家的监护学生，以及一个由兼职员工和寄宿家庭构成的网络。他们的女儿爱莉学习过中文和商业，如今在企业中担任重要职位。
          "
          src={story9}
        >
        </ImageEvent>
      </Events>
    </Timeline>
  );
}

