import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import styled from "styled-components"
import Poster from "../images/Poster.png"
import { fontSize, textAlign } from "styled-system"
import Fade from "react-reveal/Fade"
import {
  AngelaFan,
  AilieFan,
  EricFan,
  JodieDriver,
  DianneSenior,
  ZoePilling,
  YueruiSun,
  GabrielaSeczkowska,
  SarahSingleton,
  PaulaJureczeko,
  AnnieWang,
  aboutBanner,
  AnnAustin,
  BridgetLane,
  BryanChadderton,
  ChrisHolsman,
  ClareBakerSmith,
  DenisSavage,
  HarrietJohnsonTsui,
  KatieSchad,
  LouiseHarrison,
  MichaelGrive,
  NataljaFakirova,
  NikMacKenzie,
  PhilDavis,
  TamsinBailey,
  YingZhang,
  BlankPortrait,
  ChristineKwong,
} from "../images"
import { TeamContainer, TeamSubheading } from "../containers/Team"
import { Cards, Card } from "../components/TeamCards"
import ReactHtmlParser from "react-html-parser"
import { Awards, Accreditations } from "../containers/Awards&Accreditations"
import { StoryTimeline } from "../components/Timeline"
import { AboutTestimony } from "../components/Testimonials"
import TestimonialAccordion from "../components/Accordions/TestimonialAccordion"
import SEO from "../components/seo"

export const Block = styled.div`
  height: 400px;
  background-color: black;
`

export const FadeDivContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 10px;
`

const FadeParagraphContainer = styled.p`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  word-wrap: normal;
  text-align: center;
`

const FadeParagraph = ({ children }) => {
  return (
    <FadeDivContainer>
      <Fade>
        <FadeParagraphContainer>{children}</FadeParagraphContainer>
      </Fade>
    </FadeDivContainer>
  )
}

const FadeImage = props => {
  const { image, description } = props
  return (
    <Fade>
      <img
        src={image}
        style={{
          borderRadius: "10px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      />
      <div
        style={{
          fontFamily: "sans-serif",
          marginTop: "20px",
          marginBottom: "50px",
          textAlign: "left",
        }}
      >
        {description}
      </div>
    </Fade>
  )
}

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;

  @media (max-width: 1200px) {
    margin: 100px;
  }
  @media (max-width: 740px) {
    margin: 11px;
  }
`

const sidebar = [
  {
    to: "aboutUs",
    title: "关于我们",
  },
  {
    to: "ourTeam",
    title: "我们的团队",
  },
  {
    to: "ourStory",
    title: "我们的故事",
  },
  {
    to: "ourAchievements",
    title: "奖项及认可",
  },
  {
    to: "Testimonials",
    title: "口碑",
  },
  {
    to: "ourAccreditations",
    title: "认证",
  },
]

export default function About({ data }) {
  const html = data.wpPage.content
  const processedData = ReactHtmlParser(html)
  return (
    <Layout
      sidebar={sidebar}
      pageTitle="关于我们"
      background={aboutBanner}
      pageDescription="我们经验丰富，致力于帮助学生实现梦想。这是我们的故事…"
    >
      <SEO
        title="About | Guardianship | UK Education | Student Education"
        description="Study Links was founded in 1998 and recognised one of the UK’s leading and most established guardianship companies. Find out more about us. "
      />
      <Section id="aboutUs">
        <br />
        <FadeParagraph>
          Study Links 成立于 1998
          年，是一家家族企业，在业内获奖无数，我们可以自豪地说，我们是英国最著名的监护公司之一。
        </FadeParagraph>
        <FadeParagraph>
          Study Links
          团队在英国和其他国家/地区学习和教学的个人经验有助于我们更好地找到支持学生的方法。
        </FadeParagraph>
      </Section>
      <Section id="ourTeam">
        <FadeH1>我们的团队</FadeH1>
        <TeamContainer>
          <TeamSubheading>领导团队</TeamSubheading>
          <Cards>
            <Card title="Angela Fan" text="创始人" img={AngelaFan} />
            <Card title="Eric Fan" text="主任" img={EricFan} />
            <Card
              title="Ailie Fan"
              text="常务董事
"
              img={AilieFan}
            />
          </Cards>
          <Cards>
            <Card
              title="Dianne Senior"
              text="运营主管
"
              img={DianneSenior}
            />
            <Card title="Zoe Pilling" text="监护主管" img={ZoePilling} />
          </Cards>
          <TeamSubheading>金融团队</TeamSubheading>
          <Cards>
            <Card title="Sarah Singleton" text="会计" img={SarahSingleton} />
          </Cards>
          <TeamSubheading>营销团队</TeamSubheading>
          <Cards>
            <Card title="Lisa Howard" text="营销主管" img={BlankPortrait} />
          </Cards>
          <TeamSubheading>运营团队</TeamSubheading>
          <Cards>
            <Card title="Annie Wang" text="学生导师" img={AnnieWang} />
            <Card
              title="Gabriela Seczkowska"
              text="运营协调员
                "
              img={GabrielaSeczkowska}
            />
            <Card
              title="Jodie Driver"
              text="运营协调员
                "
              img={JodieDriver}
            />
          </Cards>
          <Cards>
            <Card
              title="Christine Kwong"
              text="运营协调员
                "
              img={ChristineKwong}
            />
            <Card title="Fiona Boalch" text="办公管理" img={BlankPortrait} />
          </Cards>
          <Cards>
            <Card
              title="Paula Jureczko"
              text="运营协调员
                "
              img={PaulaJureczeko}
            />
            {/* <Card title="Yuerui Sun" text="学生导师" img={YueruiSun} /> */}
          </Cards>
          <TeamSubheading>学业顾问</TeamSubheading>
          <Cards>
            <Card title="Ann Austin" text="学术顾问" img={AnnAustin} />
            <Card title="Bridget Lane" text="学术顾问" img={BridgetLane} />
            <Card
              title="Bryan Chadderton"
              text="学术顾问"
              img={BryanChadderton}
            />
          </Cards>
          <Cards>
            <Card title="Chris Holsman" text="学术顾问" img={ChrisHolsman} />
            <Card
              title="Clare Baker-Smith"
              text="学术顾问"
              img={ClareBakerSmith}
            />
            <Card title="Denis Savage" text="学术顾问" img={DenisSavage} />
          </Cards>
          <Cards>
            <Card
              title="Harriet Johnson-Tsui"
              text="学术顾问"
              img={HarrietJohnsonTsui}
            />
            <Card title="Katie Schad" text="学术顾问" img={KatieSchad} />
            <Card
              title="Louise Harrison"
              text="学术顾问"
              img={LouiseHarrison}
            />
          </Cards>
          <Cards>
            <Card title="Michael Grive" text="学术顾问" img={MichaelGrive} />
            <Card
              title="Natalja Fakirova"
              text="学术顾问"
              img={NataljaFakirova}
            />
            <Card title="Nik MacKenzie" text="学术顾问" img={NikMacKenzie} />
          </Cards>
          <Cards>
            <Card title="Phil Davis" text="学术顾问" img={PhilDavis} />
            <Card title="Stuart Mills" text="学术顾问" img={BlankPortrait} />
            <Card title="Tamsin Bailey" text="学术顾问" img={TamsinBailey} />
          </Cards>
          <Cards>
            <Card title="Ying Zhang" text="学术顾问" img={YingZhang} />
          </Cards>
        </TeamContainer>
      </Section>
      <Section id="ourStory">
        <FadeH1>我们的故事</FadeH1>
        {/* {processedData.map((data, index) => {
            return (
              <Fade>
                <div className="storyData">{data.props.children}</div>
              </Fade>
            )
          })} */}
        <br />
        <Fade>
          Study Links International 由夫妻团队 Eric 和 Angela Fan 于 1998
          年创立。在他们的辛勤工作、关怀和奉献精神的驱动下，Study Links
          不断成长，从一家由家族运营的企业发展成为如今一家仍以强烈家族精神为核心的、专注于提供优质服务的公司。
        </Fade>
        <br />
        <StoryTimeline />
        <br />
        <Fade>
          我们的公司不断发展壮大，在我们的领域内拥有丰富的知识和经验，声誉不断得以提高。我们仍然忠于公司核心的家庭价值观，让受我们照顾的每个学生都从中受益。Study
          Links
          明白他们在英国的经历对他们的未来很重要，我们帮助他们成功地度过这一阶段的生活，并灌输给他们信心、技能和工具，让他们去顺利开展下一阶段的人生之旅。
        </Fade>
      </Section>
      <Section id="ourAchievements">
        <FadeH1>奖项及认可</FadeH1>
        <Awards />
      </Section>
      <Section id="Testimonials">
        <FadeH1>口碑</FadeH1>
        <TestimonialAccordion />
      </Section>
      <Section id="ourAccreditations">
        <FadeH1>我们的认证</FadeH1>
        <Accreditations />
      </Section>
      <AboutTestimony />
    </Layout>
  )
}

export const STUDYLINKSHISTORY = graphql`
  query StudyLinksHistory {
    wpPage(title: { eq: "api-studylinks-history" }) {
      title
      content
    }
  }
`
