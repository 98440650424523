import React from 'react'
import styled from 'styled-components'

const Indented = styled.div `
  margin-left: 20px;
`

const TestimonialAccordion = () => {
  return (
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">         
              Dominic Wang – 利兹英语语言学校、圣母大学天主教高中和牛津大学的学生。
            </button>
          </h5>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
          <div class="card-body">
          “在我生命中最黑暗的日子里，Study Links 帮助了我，当时我还是一个无助的孩子，刚到外国，对语言知之甚少，总担心会发生什么。在最初的几个月里，Angela 和她的家人充当了我的向导和导师，热情地向我介绍他们的语言和文化。他们让我有了欢笑和喜悦，而不再是孤独和悲伤，使我可以有信心去追求自己内心所渴望的东西。我的寄宿家庭太好了，在和他们住了整整一年之后，我离开了他们，感到非常难过。我的监护人 Angela 每隔几个月就会带我出去看看我对自己的生活是否满意。“学校和我所期望的一样好。我的同学都是来自世界各地的不同种族，老师们随时都在那里提供帮助。正是在这些可爱的人的帮助下，我在很短的时间内就在语言上取得了很大的进步，并有足够的信心与我的英国同学竞争。“非常感谢你， Study Links如果没有 Study Links，就不会有今天的我。我向你们致以良好的祝愿，我很高兴看到越来越多的学生得到我曾经拥有的那份幸运和帮助。”
<a href="https://www.youtube.com/watch?v=BfmWCi1OqU4" target="_blank"> 视频</a>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
Vivian Chung，Grace Chung 的父母
            </button>
          </h5>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
          <div class="card-body">
          “我的女儿现在已度过了由 Study Links 监护的第一年。我想表达我最深切的谢意，感谢你们为我和我的女儿 Grace 介绍学校和一个很棒的寄宿家庭。尽管我女儿在适应英国和学校时有些困难，但今年女儿在学校的学习和生活上表现出乎意料的好。这要归因于：
        <br/>
        <br/>
          <ul>
            <li>  运用知识和同理心提供最周到安排的 Study Links 专业监护人团队。</li>
            <li>阿克沃斯学校良好的学术和行政基础。那里的工作人员和教师所具备的一贯关怀和专业精神。</li>
          </ul>
          像这样的短消息只能表达我一小部分的感激之情。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h5 class="mb-0">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
Wong 先生，Samson 和 Samuel Wong 的家长
            </button>
          </h5>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
          <div className="card-body">
          “我的两个儿子在英国上学期间，Study Links 在照顾他们。每当我的孩子们遇到任何问题，Study Links 团队都会提供帮助，提供有价值、贴心的建议和切实可行的解决方案。自从我的儿子离开英国后，我们一直与 Angela 和她的家人保持联系，我们仍然是很好的朋友。”
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFour">
          <h5 class="mb-0">
            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
Chen 太太，Linda 和 Sam Chen 的家长
            </button>
          </h5>
        </div>
        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
          <div class="card-body">
          “在我们的孩子在英国期间都得到了 Angela 和 Study Links 的大力支持。Study Links 与我们的孩子和我们都保持着定期联系，因此我们在任何时候都可以完全放心。Angela 和 Study Links 像照顾自己的孩子一样照顾我的孩子，对此我十分感激。他们由 Study Links 的照顾，我完全不用担心他们的安全。我总是向任何想把孩子送到英国的家长推荐他们的服务。”
          </div>
        </div>
      </div>
      
      
    </div>
  )
}

export default TestimonialAccordion